<template>
    <b-row>
        <b-col>
            <b-card class="shadow">
                <b-card-header header-border-variant="lighter">
                    <b-row align-v="center" align-h="between">
                        <b-col cols="auto">
                            <h3 class="mb-0 text-primary text-button">
                                RESERVAR ENTREVISTA
                            </h3>
                        </b-col>

                        <b-col cols="auto">
                            <!-- <b-button variant="magenta" size="sm" @click="reloadData">
                                <b-icon icon="collection-play-fill" scale="1.2"></b-icon>
                            </b-button> -->

                            <b-button variant="primary" size="sm" @click="reloadData">
                                <b-icon icon="arrow-repeat" scale="1.2"></b-icon>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card-header>

                <br>

                <!-- laodingTableData -->
                <div style="height:120px;" v-show="gePostulantCalendarLoading || getprogramSeasonPostulantLoading">
                    <pulse-loader
                        :color="'#5e72e4'"
                        :margin="'10px'"
                        :size="20"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,50%)"
                    />
                </div>

                <!-- alerts -->
                <div v-show="!gePostulantCalendarLoading || !getprogramSeasonPostulantLoading">
                    <div v-show="numberOfInterviews == 1 && hasAnInterviewInProgress ? true : false" >
                        <v-alert border="top" elevation="1" outlined prominent text type="warning" >
                            No puedes reservar, ya tienes una entrevista en curso agendada
                        </v-alert>
                    </div>

                    <div v-show="numberOfInterviews == 2 ? true : false" >
                        <v-alert border="top" elevation="1" outlined prominent text type="warning" >
                            No puedes reservar, ya agendaste el número máximo de entrevistas.
                        </v-alert>
                    </div>
                    
                    <div v-show="hasAnInterviewPassed" >
                        <v-alert border="top" elevation="1" outlined prominent text type="warning" >
                            No puedes reservar, ya pasaste la entrevista.
                        </v-alert>
                    </div>
                </div>

                <!-- calendars -->
                <v-card 
                    v-show="!gePostulantCalendarLoading && !getprogramSeasonPostulantLoading && (numberOfInterviews < 2 && !hasAnInterviewInProgress && !hasAnInterviewPassed) ? true : false" elevation="1"
                 >
                    <v-tabs v-model="tab" background-color="transparent"  grow >
                        <v-tab v-if="programSeasonPostulantData.attributes.salesAdvisor_id ? true : false" >
                            Asesor de ventas
                        </v-tab>

                        <v-tab v-if="programSeasonPostulantData.attributes.culturalAdvisor_id ? true : (programSeasonPostulantData.attributes.culturalAdvisorAssigned_id ? true : false)" >
                            Asesor cultural
                        </v-tab>
                    </v-tabs>

                    <v-tabs-items v-model="tab">
                        <v-tab-item v-if="programSeasonPostulantData.attributes.salesAdvisor_id ? true : false">
                            <v-card elevation="4" class="mt-5">
                                <sales-advisor-calendar :salesAdvisor_id="programSeasonPostulantData.attributes.salesAdvisor_id"/>
                            </v-card>
                        </v-tab-item>

                        <v-tab-item v-if="programSeasonPostulantData.attributes.culturalAdvisor_id ? true : (programSeasonPostulantData.attributes.culturalAdvisorAssigned_id ? true : false)">
                            <v-card elevation="4" class="mt-5">
                                <cultural-advisor-calendar :culturalAdvisor_id="programSeasonPostulantData.attributes.culturalAdvisor_id ? programSeasonPostulantData.attributes.culturalAdvisor_id : programSeasonPostulantData.attributes.culturalAdvisorAssigned_id"/>
                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>
                </v-card>
                
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import { getProgramSeasonPostulantInterviews } from '@/api/ProgramSeasonPostulant/EnglishInterviewSchedules';
import { getProgramSeasonPostulant } from '@/api/ProgramSeasonPostulant/ProgramSeasonPostulant';
//import { PulseLoader } from '@saeris/vue-spinners'
// components
import SalesAdvisorCalendar from './components/SalesAdvisorCalendar.vue';
import CulturalAdvisorCalendar from './components/CulturalAdvisorCalendar.vue';

export default {
    name: "ScheduleInterview",
    components: {
        // PulseLoader,
        SalesAdvisorCalendar,
        CulturalAdvisorCalendar,
    },
    data: () => ({
        /* programSeasonPostulant.englishInterviewSchedules */
        PostulantCalendarData: [],
        gePostulantCalendarLoading: false,
        /* programSeasonPostulant */
        programSeasonPostulantData: { attributes: {} },
        getprogramSeasonPostulantLoading: false,
        //
        hasAnInterviewPassed: false,
        hasAnInterviewInProgress: false,
        numberOfInterviews: '',

        //
        tab: null,
    }),

    created() {
        this.getPostulantInterviewsData()
        this.getProgramSeasonPostulantDaTa()
    },

    methods: {
        getPostulantInterviewsData () {
            this.gePostulantCalendarLoading = true

            //
            let IdProgramSeasonPostulant = this.$route.params.IdProgramSeasonPostulant    // ok

            getProgramSeasonPostulantInterviews(IdProgramSeasonPostulant)
                .then(({ data }) => {
                    // numberOfInterviews
                    this.numberOfInterviews = data.data.length

                    //hasAnInterviewInProgress
                    if (data.data.length != 0) {
                        for (const el of data.data) {
                            if (el.attributes.schedule_status == 'pendiente'){
                                this.hasAnInterviewInProgress = true
                                break
                            } else if (el.attributes.interview_status == 'aprobado') {
                                this.hasAnInterviewPassed = true
                                break
                            }
                        }
                    }
                })
                .catch( err => {
                    console.log('getPostulantInterviews Error:', err.response)
                })
                .finally(() => {
                    this.gePostulantCalendarLoading = false
                })
        },

        getProgramSeasonPostulantDaTa () {
            this.getprogramSeasonPostulantLoading = true

            let IdProgramSeasonPostulant = this.$route.params.IdProgramSeasonPostulant // ok

            getProgramSeasonPostulant(IdProgramSeasonPostulant)
                .then(({ data }) => {
                    this.programSeasonPostulantData = data.data
                })
                .catch( err => {
                    console.log('getProgramSeasonPostulant Error:', err.response)
                })
                .finally(() => {
                    this.getprogramSeasonPostulantLoading = false
                })
        },

        reloadData () {
            this.getPostulantInterviewsData()
            this.getProgramSeasonPostulantDaTa()
        }
    }
}
</script>