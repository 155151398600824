<template>
    <contracts-list-component
        :routeNameToContractCreate="route_name_create"
        :routeNameToContractDetails="route_name_details">
    </contracts-list-component>
</template>

<script>
import ContractsListComponent from '@/components/Admin-Employee/Postulant/Contracts/ContractsListComponent.vue'

export default {
  components: { ContractsListComponent },
    name: 'ContractsList',
    data: () => ({
        route_name_create: 'employee-postulants-contract-create-SA',
        route_name_details: 'employee-postulants-contracts-details-SA'
    })
}
</script>