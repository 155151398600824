<template>
    <contract-register-html-component :routeNameToContractPdfList="route_name"></contract-register-html-component>
</template>

<script>
import ContractRegisterHtmlComponent from '@/components/HotFix/Contract/ContractPdf/ContractRegisterHtmlComponent.vue'

export default {
  components: { ContractRegisterHtmlComponent },
    name: 'ContractRegisterHtml',
    data: () => ({
        route_name: 'employee-hotfix-contracts-pdfs'
    })
}
</script>