<template>
    <b-row >
        <!-- EnglishInterviewSchedule details -->
        <b-col cols="12" class="mb-4">
            <b-card class="shadow" >
                <b-card-header>
                    <b-row class="align-items-center">
                        <b-col>
                            <h3 class="mb-0 text-primary text-button">
                                Detalles de la Entrevista
                            </h3>
                        </b-col>

                        <b-col cols="auto">
                            <b-button variant="primary" size="sm" @click="reloadData">
                                <b-icon icon="arrow-repeat"></b-icon>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card-header>

                <!-- laoding programSeasonPostulant -->
                <div style="height:300px;" v-show="getEnglishInterviewLoading">
                    <pulse-loader
                        :color="'#5e72e4'"
                        :margin="'10px'"
                        :size="20"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)"/>
                </div>

                <!-- programSeasonPostulant details -->
                <div v-show="!getEnglishInterviewLoading" class="mt-8">
                    <b-list-group>
                        <b-list-group-item>
                            <b-row>
                                <b-col>Estado de la entrevista</b-col>
                                <b-col cols="1">:</b-col>
                                <b-col :class="englishInterviewSchedule.attributes.schedule_status == 'pendiente' ? 'text-yellow' : 'text-primary'">
                                    {{ englishInterviewSchedule.attributes.schedule_status }}</b-col>
                            </b-row>
                        </b-list-group-item>

                        <b-list-group-item>
                            <b-row>
                                <b-col>Link</b-col>
                                <b-col cols="1">:</b-col>
                                <b-col>
                                    <b-btn
                                        :href="englishInterviewSchedule.attributes.link" target="_blanck"
                                        size="sm"
                                        variant="outline-primary"
                                    ><b-icon icon="link"></b-icon> url 
                                    </b-btn>
                                </b-col>
                            </b-row>
                        </b-list-group-item>

                        <b-list-group-item>
                            <b-row>
                                <b-col>Fecha</b-col>
                                <b-col cols="1">:</b-col>
                                <b-col>
                                    {{ englishInterviewSchedule.attributes.start_date }}
                                    </b-col>
                            </b-row>
                        </b-list-group-item>

                        <b-list-group-item>
                            <b-row>
                                <b-col>Hora</b-col>
                                <b-col cols="1">:</b-col>
                                <b-col>{{ englishInterviewSchedule.attributes.start_time }} a {{ englishInterviewSchedule.attributes.end_time }}</b-col>
                            </b-row>
                        </b-list-group-item>
                    </b-list-group>

                    <b-list-group v-if="englishInterviewSchedule.attributes.postulant_status" class="mt-5">
                        <b-list-group-item variant="primary">
                            <b-row>
                                <b-col class="text-upper text-center">Resultados</b-col>
                            </b-row>
                        </b-list-group-item>

                        <b-list-group-item>
                            <b-row>
                                <b-col>Postulante</b-col>
                                <b-col cols="1">:</b-col>
                                <b-col>{{ englishInterviewSchedule.attributes.postulant_status }}</b-col>
                            </b-row>
                        </b-list-group-item>

                        <b-list-group-item>
                            <b-row>
                                <b-col>Resultado de la entrevista</b-col>
                                <b-col cols="1">:</b-col>
                                <b-col>
                                    <b-iconstack font-scale="1.3" class="mr-3">
                                        <b-icon stacked icon="square" scale="1.6" variant="lighter"></b-icon>
                                        <b-icon stacked scale="1.6"
                                            v-if="englishInterviewSchedule.attributes.interview_status"
                                            :icon="englishInterviewSchedule.attributes.interview_status == 'aprobado' ? 'check' : 'x'"
                                            :variant="englishInterviewSchedule.attributes.interview_status == 'aprobado' ? 'green' : 'danger'"
                                        ></b-icon>
                                        <b-icon stacked icon="square-fill" scale="1.1" variant="lighter" v-else></b-icon>
                                    </b-iconstack>

                                    <span :class="englishInterviewSchedule.attributes.interview_status == 'aprobado' ? 'text-green' : 'text-danger'">
                                        {{ englishInterviewSchedule.attributes.interview_status }}
                                    </span>
                                </b-col>
                            </b-row>
                        </b-list-group-item>

                        <b-list-group-item v-if="englishInterviewSchedule.attributes.postulant_status == 'asistio'">
                            <b-row>
                                <b-col>Listening</b-col>
                                <b-col cols="1">:</b-col>
                                <b-col>
                                    <div>{{ englishInterviewSchedule.attributes.listening_nivel }}</div>
                                    <div>{{ englishInterviewSchedule.attributes.listening_comment }}</div>
                                </b-col>
                            </b-row>
                        </b-list-group-item>

                        <b-list-group-item v-if="englishInterviewSchedule.attributes.postulant_status == 'asistio'">
                            <b-row>
                                <b-col>Speaking</b-col>
                                <b-col cols="1">:</b-col>
                                <b-col>
                                    <div>{{ englishInterviewSchedule.attributes.speaking_nivel }}</div>
                                    <div>{{ englishInterviewSchedule.attributes.speaking_comment }}</div>
                                </b-col>
                            </b-row>
                        </b-list-group-item>

                        <b-list-group-item v-if="englishInterviewSchedule.attributes.postulant_status == 'asistio'">
                            <b-row>
                                <b-col>Vocabulary</b-col>
                                <b-col cols="1">:</b-col>
                                <b-col>
                                    <div>{{ englishInterviewSchedule.attributes.vocabulary_nivel }}</div>
                                    <div>{{ englishInterviewSchedule.attributes.vocabulary_comment }}</div>
                                </b-col>
                            </b-row>
                        </b-list-group-item>

                        <b-list-group-item v-if="englishInterviewSchedule.attributes.interview_details">
                            <b-row>
                                <b-col>Comentarios</b-col>
                                <b-col cols="1">:</b-col>
                                <b-col>{{ englishInterviewSchedule.attributes.interview_details }}</b-col>
                            </b-row>
                        </b-list-group-item>

                        <b-list-group-item v-if="englishInterviewSchedule.attributes.interview_observations">
                            <b-row>
                                <b-col>Observaciones</b-col>
                                <b-col cols="1">:</b-col>
                                <b-col>{{ englishInterviewSchedule.attributes.interview_observations }}</b-col>
                            </b-row>
                        </b-list-group-item>
                    </b-list-group>
                </div>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import { getEnglishInterviewSchedule } from '@/api/EnglishInterviewSchedule/EnglishInterviewSchedule';

export default {
    name: "EnglishInterviewDetailsComponent",
    data: () => ({
        englishInterviewSchedule: {
            attributes: {}
        },
        getEnglishInterviewLoading: false,
        /**
         * MODAL: SET-RESULTS
         */
        englishLevel: [
            { value: 'nivel 1', text: 'nivel 1 - muy básico'},
            { value: 'nivel 2', text: 'nivel 2 - básico'},
            { value: 'nivel 3', text: 'nivel 3 - intermedio'},
            { value: 'nivel 4', text: 'nivel 4 - bueno'},
            { value: 'nivel 5', text: 'nivel 5 - muy bueno'}
        ],
        //
        inputModalSetResultsErrors: {},
        initialModalSetResultsInputValues: true,
        setResultsLoading: false,

        /**
         * MODAL: SCHEDULE
         */
        inputModalScheduleErrors: {},
        initialModalScheduleInputValues: true,
        updateScheduleLoading: false,
    }),

    created() {
        this.getData()
    },

    methods: {
        getData () {
            this.getEnglishInterviewLoading = true
            let IdEnglishInterviewSchedule = this.$route.params.IdEnglishInterviewSchedule // ok
            getEnglishInterviewSchedule(IdEnglishInterviewSchedule)
                .then(({ data }) => {
                    this.englishInterviewSchedule = data.data
                })
                .finally(() => {
                    this.getEnglishInterviewLoading = false
                })
        },

        whatIsEnglishNivel(pNivel) {
            if (pNivel == 'nivel 1') {
                return 'Nivel 1 - muy básico'

            } else if (pNivel == 'nivel 2'){
                return 'Nivel 2 - básico'

            } else if (pNivel == 'nivel 3'){
                return 'Nivel 3 - intermedio'

            } else if (pNivel == 'nivel 4'){
                return 'Nivel 4 - bueno'

            } else if (pNivel == 'nivel 5') {
                return 'Nivel 5 - muy bueno'
                
            } else {
                return '...'
            }
        },

        reloadData () {
            this.getData()
        }
    }
}
</script>