<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
                     style="min-height: 600px; background-image: url(/img/theme/profile-cover.jpg);
                      background-size: cover; background-position: center 10%;">
            <!-- Mask -->
            <span class="mask bg-gradient-violeta opacity-7"></span>
            <!-- Header container -->
            <div class="container-fluid d-flex align-items-center">
                <div class="row">
                    <div class="col-lg-7 col-md-10">
                        <h1 class="display-2 text-white">DASHBOARD</h1>
                        <h2 > Not active roles</h2>
                    </div>
                </div>
            </div>
        </base-header>

    </div>
</template>

<script>

  export default {
    data() {
      return {
      };
    }
  };
</script>
<style></style>

