<template>
    <intranet-nav-content>
        <template slot="links">
            <intranet-nav-content-link-exact :link="{ name: 'P. Postergados', path: { name: 'employee-postponements-list' } }"/>
            <!-- <intranet-nav-content-link :link="{ name: 'Ofertas laborales - Campos Generales', path: { name: 'admin-sponsor-general-job-inputs' } }"/> -->
        </template>
    </intranet-nav-content>
</template>

<script>
import IntranetNavContent from '@/layout/NavContent/IntranetNavContent';
import IntranetNavContentLinkExact from '@/layout/NavContent/IntranetNavContentLinkExact';
// import IntranetNavContentLink from '@/layout/NavContent/IntranetNavContentLink';

export default {
    name: 'Postponements',
    components: { 
        IntranetNavContent,
        IntranetNavContentLinkExact,
        // IntranetNavContentLink
    },
    data: () => ({})
};
</script>
