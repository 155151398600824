<template>
    <b-row>
        <b-col>
            <b-card class="shadow" no-body>
                <b-card-header class="border-0">
                    <b-row class="align-items-center">
                        <b-col>
                            <h3 class="mb-0 text-primary text-button">
                                Lista de entrevistas
                            </h3>
                        </b-col>

                        <b-col cols="auto">
                            <b-button
                                :variant="tableData.length >= 2 ?'light' : 'naranja'"
                                size="sm" @click="modalAddInterviewClick"
                                :disabled="loadingTableData || tableData.length >= 2"
                            >
                                <b-icon icon="calendar2-plus" scale="1.3"></b-icon>
                            </b-button>

                            <b-button variant="primary" size="sm" @click="reloadData">
                                <b-icon icon="arrow-repeat"></b-icon>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card-header>

                <!-- laodingTableData -->
                <div style="height:300px;" v-show="loadingTableData">
                    <pulse-loader
                        :color="'#5e72e4'"
                        :margin="'10px'"
                        :size="20"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,50%)"/>
                </div>
                
                <!-- table salesAdvisor.season.programSeasonPostulants -->
                <div class="table-responsive" v-show="!loadingTableData">
                    <base-table class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="tableData"
                    >
                        <template slot="columns">
                            <th>#</th>
                            <th></th>
                            <th>Subject</th>
                            <th>Fecha</th>
                            <th>Hora</th>
                            <th>Entrevistador</th>
                            <th>Estado</th>
                            <th></th>
                        </template>

                        <template slot-scope="{row, index}">
                            <td>
                                {{ index + 1 }}
                            </td>

                            <td class="text-right">
                                <b-btn 
                                    :to="{
                                        name: routeToDetails,
                                        params: { 'IdEnglishInterviewSchedule': row.id }    // IdEnglishInterviewSchedule
                                    }"
                                    variant="outline-violeta"
                                    size="sm"
                                >
                                    <b-icon icon="list-task" ></b-icon>
                                </b-btn>
                            </td>

                            <td>Entrevista de Inglés</td>
                            <td>{{ row.attributes.start_date }}</td>
                            <td>{{ row.attributes.start_time }} a {{ row.attributes.end_time }}</td>

                            <td>
                                 <div v-show="row.attributes.salesAdvisor_id != null ? true : false">
                                    SA : {{ row.attributes.salesAdvisor_full_name }}
                                </div>

                                <div v-show="row.attributes.culturalAdvisor_id != null ? true : false">
                                    CA : {{ row.attributes.culturalAdvisor_full_name }}
                                </div>
                            </td>

                            <td :class="row.attributes.schedule_status == 'culminado' ?  'text-green' : 'text-yellow'">
                                {{ row.attributes.schedule_status }}
                            </td>

                            <td>
                                <b-button size="sm" @click="deleteEnglishInterviewSchedule(row.attributes)">
                                    <b-icon icon="trash" variant="danger"></b-icon>
                                </b-button>
                            </td>

                        </template>
                    </base-table>
                </div>
            </b-card>
        </b-col>

        <!-- 
            modal add interview
        -->
        <b-modal
            id="modal-add-interview"
            centered
            hide-footer
            size="md"
            no-close-on-backdrop
            no-close-on-esc
        >
            <!-- modal header -->
            <template #modal-header>
                <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                    <b-row align-h="between" align-v="center">
                        <b-col cols="auto"><h3 class="text-primary mb-0 text-button">AGENDAR ENTREVISTA</h3></b-col>
                        <b-col cols="auto">
                            <b-button size="sm" variant="magenta" @click="modalAddInterviewClose">
                                <b-icon icon="x" scale="1.5"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <!-- modal content -->
            <template #default>
                <b-container>
                    <b-row>
                        <!-- orm -->
                        <b-col cols="12">
                            <b-form @submit.prevent="addInterview">
                                <b-form-row>
                                    <!-- start_date -->
                                    <b-col cols="12">
                                        <b-form-group
                                            label="Fecha :"
                                        >
                                            <pulse-loader
                                                :loading="modalInputsLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-datepicker
                                                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                selected-variant="primary"
                                                today-variant="naranja"
                                                locale="es"

                                                today-button
                                                label-today-button="today"
                                                today-button-variant="outline-naranja"
                                                
                                                reset-button
                                                label-reset-button="reset"
                                                reset-button-variant="outline-magenta"

                                                close-button
                                                label-close-button="ok"
                                                close-button-variant="primary"

                                                v-model="start_date"
                                                :state="showModalInputStatus('start_date')"
                                                v-if="!modalInputsLoading"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('start_date')"
                                                :key="`${i}-interview-start_date`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>

                                    <!-- start_time -->
                                    <b-col cols="12" lg="6">
                                        <b-form-group
                                            label="Hora de inicio :"
                                        >
                                            <pulse-loader
                                                :loading="modalInputsLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-timepicker
                                                locale="en"

                                                now-button
                                                label-now-button="now"
                                                now-button-variant="outline-naranja"

                                                reset-button
                                                label-reset-button="reset"
                                                reset-button-variant="outline-magenta"

                                                close-button
                                                label-close-button="ok"
                                                close-button-variant="primary"

                                                v-model="start_time"
                                                :state="showModalInputStatus('start_time')"
                                                v-if="!modalInputsLoading"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('start_time')"
                                                :key="`${i}-interview-start_time`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>

                                    <!-- end_time -->
                                    <b-col cols="12" lg="6">
                                        <b-form-group
                                            label="Hora de finalización :"
                                        >
                                            <pulse-loader
                                                :loading="modalInputsLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-timepicker
                                                locale="en"

                                                now-button
                                                label-now-button="now"
                                                now-button-variant="outline-naranja"

                                                reset-button
                                                label-reset-button="reset"
                                                reset-button-variant="outline-magenta"

                                                close-button
                                                label-close-button="ok"
                                                close-button-variant="primary"

                                                v-model="end_time"
                                                :state="showModalInputStatus('end_time')"
                                                v-if="!modalInputsLoading"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('end_time')"
                                                :key="`${i}-interview-end_time`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>

                                    <!-- link -->
                                    <b-col cols="12">
                                        <b-form-group label="Link :" >
                                            <pulse-loader
                                                :loading="modalInputsLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-input
                                                type="url"
                                                placeholder="Ingrese el link de la reunión."
                                                v-model="link"
                                                :state="showModalInputStatus('link')"
                                                v-show="!modalInputsLoading"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('link')"
                                                :key="`${i}-createVoucher-link`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>

                                    <!-- description -->
                                    <b-col cols="12">
                                        <b-form-group
                                            label="Descripción:" 
                                        >
                                            <pulse-loader
                                                :loading="modalInputsLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-textarea 
                                                placeholder="Ingrese una descripción (opcional)"
                                                v-model="schedule_description"
                                                :state="showModalInputStatus('schedule_description')"
                                                v-show="!modalInputsLoading"
                                                rows="3"
                                                max-rows="6"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('schedule_description')"
                                                :key="`${i}-interview-schedule_description`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <!-- switch -->
                                <b-form-row>
                                    <b-col>
                                        <b-form-group
                                            label="¿Registrar resultados de la entrevista? "
                                        >
                                            <pulse-loader
                                                :loading="modalInputsLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-checkbox
                                                v-model="switchResultados"
                                                v-show="!modalInputsLoading"
                                                switch
                                                value="si"
                                                unchecked-value="no"
                                                @change="switchResultadosChanged"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <!-- interview_details -->
                                <transition name="slide-fade">
                                <b-form-row v-show="switchResultados == 'si'">
                                    <b-col cols="12">
                                        <b-form-group 
                                            label="Observaciones :"
                                        >
                                            <pulse-loader
                                                :loading="modalInputsLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-textarea
                                                placeholder="Ingrese el resultado de la entrevista"
                                                v-model="interview_details"
                                                :state="showModalInputStatus('interview_details')"
                                                v-show="!modalInputsLoading"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('interview_details')"
                                                :key="`${i}-createPostulant-description`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>
                                </transition>

                                <!-- nivel -->
                                <transition name="slide-fade">
                                <b-form-row v-show="switchResultados == 'si'">
                                    <b-col>
                                        <b-form-group label="Nivel de Inglés :">
                                            <pulse-loader
                                                :loading="modalInputsLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-select
                                                :options="englishLevel"
                                                v-model="nivel"
                                                :state="showModalInputStatus('nivel')"
                                                v-show="!modalInputsLoading" 
                                            >
                                                <template #first>
                                                    <b-form-select-option value=null disabled>-- seleciona un nivel --</b-form-select-option>
                                                </template>
                                            </b-form-select>

                                            <b-form-invalid-feedback 
                                                v-for="(inputError, i) in showModalInputErrors('nivel')"
                                                :key="`${i}-createPostulant-nivel`"
                                                :state="showModalInputStatus('nivel')"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>
                                </transition>

                                <!-- interview_status -->
                                <transition name="slide-fade">
                                <b-form-row v-show="switchResultados == 'si'">
                                    <b-col>
                                        <b-form-group label="Resultado de la entrevista :">
                                            <pulse-loader
                                                :loading="modalInputsLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-radio-group
                                                plain
                                                v-model="interview_status"
                                                :state="showModalInputStatus('interview_status')"
                                                v-show="!modalInputsLoading"
                                            >
                                                <b-form-radio value="aprobado">Aprobado</b-form-radio>
                                                <b-form-radio value="reprobado">Reprobado</b-form-radio>
                                            </b-form-radio-group>

                                            <b-form-invalid-feedback 
                                                v-for="(inputError, i) in showModalInputErrors('interview_status')"
                                                :key="`${i}-createPostulant-description`"
                                                :state="showModalInputStatus('interview_status')"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>
                                </transition>

                                <!-- postulant_status -->
                                <transition name="slide-fade">
                                <b-form-row v-show="switchResultados == 'si'">
                                    <b-col>
                                        <b-form-group label="Del Postulante :">
                                            <pulse-loader
                                                :loading="modalInputsLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-radio-group
                                                plain
                                                v-model="postulant_status"
                                                :state="showModalInputStatus('postulant_status')"
                                                v-show="!modalInputsLoading"
                                            >
                                                <b-form-radio value="asistio">Asistió a la entrevista </b-form-radio>
                                                <b-form-radio value="no asistio">No asistió a la entrevista</b-form-radio>
                                            </b-form-radio-group>

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('postulant_status')"
                                                :key="`${i}-createPostulant-description`"
                                                :state="showModalInputStatus('postulant_status')"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>
                                </transition>

                                <!-- request error -->
                                <b-row v-if="requestError != ''">
                                    <b-col>
                                        <b-alert show variant="magenta">
                                            {{ requestError }}
                                        </b-alert>
                                    </b-col>
                                </b-row>

                                <b-row align-v="center" align-h="center">
                                    <b-col cols="auto">
                                        <b-button variant="primary" type="submit"
                                            :disabled="modalInputsLoading"
                                        >
                                            REGISTRAR
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </b-col>
                    </b-row>
                </b-container>
            </template>
        </b-modal>
        <!-- end modal add interview -->

    </b-row>
</template>

<script>
import { getProgramSeasonPostulantInterviews } from '@/api/ProgramSeasonPostulant/EnglishInterviewSchedules';
import { storeEnglishInterviewSchedule, destroyEnglishInterviewSchedule } from '@/api/EnglishInterviewSchedule/EnglishInterviewSchedule'
import swal from 'sweetalert'

export default {
    name: "EnglishInterviewListComponent",
    props: {
        ui: {
            type: String,
            required: true
        },
        routeToDetails: {
            type: String,
            required: true
        }
    },
    data: () => ({
        //
        switchResultados: 'no',
        //
        tableData: [],
        loadingTableData: false,
        // modal
        subject: 'Entrevista de Inglés',            // default
        start_date: '',
        start_time: '',
        end_date: '',
        end_time: '',
        link: '',
        color: '#5E8B7E',
        schedule_description: '',
        schedule_status: 'pendiente',                        // disponible pendiente culminado

        interview_details: '',                      //
        interview_status: '',                       //
        postulant_status: '',                       //
        nivel: null,                                  // nivel 1 --5

        englishLevel: [
            { value: 'nivel 1', text: 'nivel 1 - muy básico'},
            { value: 'nivel 2', text: 'nivel 2 - básico'},
            { value: 'nivel 3', text: 'nivel 3 - intermedio'},
            { value: 'nivel 4', text: 'nivel 4 - bueno'},
            { value: 'nivel 5', text: 'nivel 5 - muy bueno'}
        ],

        initialModalInputValues: true,
        modalInputErrors: {},
        modalInputsLoading: false,

        requestError: ''
    }),

    created() {
        this.getPostulantInterviewsData()
    },

    computed: {
        culturalAdvisorPermissionData () {
            return this.$store.getters['user/getEmployeePermissionData']('asesor-cultural')
        },
        salesAdvisorPermissionData () {
            return this.$store.getters['user/getEmployeePermissionData']('asesor-ventas')
        },
    },

    methods: {
        getPostulantInterviewsData () {
            this.loadingTableData = true
            let IdProgramSeasonPostulant = this.$route.params.IdProgramSeasonPostulant    // ok

            getProgramSeasonPostulantInterviews(IdProgramSeasonPostulant)
                .then(({ data }) => {
                    this.tableData = data.data
                    this.tableMeta = data.meta
                })
                .finally(() => {
                    this.loadingTableData = false
                })
        },

        switchResultadosChanged(e) {
            if (e == 'si') {
                this.link = 'https://example.com'
                this.schedule_status = 'culminado'
            } else {
                this.link = ''
                this.schedule_status = 'pendiente'
                this.interview_details = ''
                this.interview_status = ''
                this.postulant_status = ''
                this.nivel = null
            }
        },

        // MODAL ADD INTERVIEW
        modalAddInterviewClick () {
            this.clearModalInputsAndStates()
            this.$bvModal.show('modal-add-interview')
        },
        modalAddInterviewClose () {
            this.$bvModal.hide('modal-add-interview')
            this.clearModalInputsAndStates()
        },

        addInterview() {
            this.modalInputsLoading = true
            this.modalInputErrors = {}
            this.requestError = ''
            this.initialModalInputValues = false

            //
            let IdSalesAdvisor = this.salesAdvisorPermissionData ? this.salesAdvisorPermissionData.attributes.typeAdvisor_id : ''          // ok
            let IdCulturalAdvisor = this.culturalAdvisorPermissionData ? this.culturalAdvisorPermissionData.attributes.typeAdvisor_id : ''  // ok

            let IdProgramSeasonPostulant = this.$route.params.IdProgramSeasonPostulant   // ok

            const InterviewFormData = new FormData()
            InterviewFormData.append('employeeCalendar_id', this.$store.state.user.attributes.employeeCalendar_id)
            if (this.ui == 'sales_advisor') InterviewFormData.append('salesAdvisor_id', IdSalesAdvisor)                  // cuando la agenda es de SA
            if (this.ui == 'cultural_advisor') InterviewFormData.append('culturalAdvisor_id', IdCulturalAdvisor)               // cuando la agenda es de CA
            InterviewFormData.append('programSeasonPostulant_id', IdProgramSeasonPostulant)    // para agendar a un postulante

            InterviewFormData.append('start_date', this.start_date)
            InterviewFormData.append('start_time', this.start_time)
            InterviewFormData.append('end_date', this.start_date)                   // this.start_date; la entrevista es el mismo dia
            InterviewFormData.append('end_time', this.end_time)

            InterviewFormData.append('link', this.link)
            InterviewFormData.append('color', this.color)                               // '#5E8B7'
            InterviewFormData.append('schedule_description', this.schedule_description) // 'Entrevista de Inglés'

            InterviewFormData.append('schedule_status', this.schedule_status)

            InterviewFormData.append('switchResultados', this.switchResultados)

            if(this.switchResultados == 'si'){
                InterviewFormData.append('interview_details', this.interview_details)
                InterviewFormData.append('interview_status', this.interview_status)
                InterviewFormData.append('postulant_status', this.postulant_status)
                InterviewFormData.append('nivel', this.nivel)
            }

            storeEnglishInterviewSchedule(InterviewFormData)
                .then(() => {
                    swal('Creación correcta!', 'Entrevista de Inglés creada.', 'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    this.getPostulantInterviewsData()
                                    this.modalAddInterviewClose()
                                    break;
                                default:
                                    console.log('create english interview res swal-break');
                                    break;
                            }
                        })
                })
                .catch(err => {
                    if (err.response.status == 422) {
                        this.modalInputErrors = err.response.data.errors
                    } else {
                        this.requestError = err.response.status + ' : ' + err.response.data.message
                    }
                })
                .finally(() => {
                    this.modalInputsLoading = false
                })
        },

        showModalInputErrors (pInput) {
            if (Object.keys(this.modalInputErrors).includes(pInput)) return this.modalInputErrors[pInput]
            else return []
        },

        showModalInputStatus (pInput) {
            if (this.initialModalInputValues) return null 
            else if (Object.keys(this.modalInputErrors).includes(pInput)) return false
            else return true
        },

        clearModalInputsAndStates () {
            this.initialModalInputValues = true
            this.modalInputErrors = {}
            //
            this.start_date = '',
            this.start_time = '',
            this.end_date = '',
            this.end_time = '',
            this.link = '',
            this.color = '#5E8B7E',
            this.schedule_description = '',
            this.schedule_status = 'pendiente'                  // disponible pendiente culminado

            this.switchResultados = 'no'

            this.interview_details = '',
            this.interview_status = '',
            this.postulant_status = '',
            this.nivel = null

            this.requestError = ''
        },

        // DELETE
        deleteEnglishInterviewSchedule (pEnglishInterviewSchedule) {
            swal('Se eliminará la entrevista.', '¿Desea eliminar?', 'error', { dangerMode: true, buttons: ['cancelar', 'Si, eliminar']})
                    .then(value => {
                        switch (value) {
                            case true:
                                destroyEnglishInterviewSchedule(pEnglishInterviewSchedule.id)
                                    .then(() => {
                                         swal('Se eliminó la entrevista!', 'Eliminado', 'success')
                                            .then(value => {
                                                switch (value) {
                                                    case true:
                                                    case null:
                                                        this.getPostulantInterviewsData()
                                                        break;
                                                    default:
                                                        console.log('delete-eng-interview-swal-break');
                                                        break;
                                                }
                                            })
                                    })
                                break;
                            case null:
                                break;
                            default:
                                console.log('delete-eng-interview-swal-break');
                                break;
                        }
                    })
        },

        reloadData () {
            this.getPostulantInterviewsData()
        }
    }
}
</script>

<style scoped>
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all .5s ease;
}
.slide-fade-leave-active {
  transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
