<template>
    <b-row class="mb-4">
        <b-col>
            <b-card class="shadow" no-body>
                <b-card-header class="border-0">
                    <b-row class="align-items-center">
                        <b-col>
                            <h3 class="mb-0 text-primary text-button">
                                Lista de tópicos de capacitaciones
                            </h3>
                        </b-col>

                        <b-col class="text-right">
                            <b-button variant="green" size="sm" @click="modalCapacitationCreateClick">
                                <b-icon icon="plus"></b-icon>
                            </b-button>
                            <b-button variant="primary" size="sm" @click="reloadData">
                                <b-icon icon="arrow-repeat"></b-icon>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card-header>

                <!-- loadingTableData -->
                <div style="height:300px;" v-show="loadingTableData">
                    <pulse-loader
                        :color="'#5e72e4'"
                        :margin="'10px'"
                        :size="20"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%, -50%)"/>
                </div>

                <!-- table -->
                <div class="table-responsive" v-show="!loadingTableData">
                    <base-table class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="tableData"
                    >
                        <template slot="columns">
                            <th>#</th>
                            <th><div>video</div><div>(link)</div></th>
                            <th>nombre</th>
                            <th>estado</th>
                            <th>descripción</th>
                            <th></th>
                            <th></th>
                        </template>

                        <template slot-scope="{row, index}">
                            <td>{{ index + 1 }}</td>
                            <td>
                                <b-button variant="outline-primary" size="sm" :href="row.attributes.link" target="_black">
                                    <b-icon icon="image" scale="1.2"></b-icon>
                                </b-button>
                            </td>
                            <td class="text-overline">{{ row.attributes.topic_name }}</td>
                            <td >{{ row.attributes.status }}</td>
                            <td>
                                {{ row.attributes.description ? row.attributes.description.substr(0, 60) : '' }} ...
                            </td>

                            <td class="text-right">
                                <b-btn size="sm" @click="modalCapacitationEditClick(row.attributes)">
                                    <b-icon icon="pencil-square" scale="1.2" variant="primary"></b-icon>
                                </b-btn>
                            </td>
                            <td class="text-right">
                                <b-btn size="sm" @click="deleteCapacitation(row.attributes)">
                                    <b-icon icon="trash" scale="1.2" variant="danger"></b-icon>
                                </b-btn>
                            </td>
                        </template>
                    </base-table>
                </div>
            </b-card>
        </b-col>

        <!--
            modal capacitation topic create
        -->
        <b-modal
            id="modal-capacitation-create-topic"
            centered
            hide-footer
            size="md"
            no-close-on-backdrop
            no-close-on-esc
        >
            <!-- modal header -->
            <template #modal-header="">
                <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                    <b-row align-h="between" align-v="center">
                        <b-col cols="auto"><h3 class="text-primary mb-0 text-button">CREAR TÓPICO DE CAPACITACION</h3></b-col>
                        <b-col cols="auto">
                            <b-button size="sm" variant="magenta" @click="modalCapacitationCreateClose">
                                <b-icon icon="x" scale="1.5"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <!-- modal content -->
            <template #default>
                <b-container>
                    <b-row>
                        <!-- capacitation form -->
                        <b-col cols="12">
                            <b-form @submit.prevent="createCapacitationTopic">
                                <!-- topic_name -->
                                <b-form-row>
                                    <b-col>
                                        <b-form-group
                                            label="Nombre : "
                                        >
                                            <pulse-loader
                                                :loading="createCapacitationLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-input
                                                type="text"
                                                v-model="topic_name"
                                                :state="showModalInputStatus('topic_name')"
                                                v-show="!createCapacitationLoading"
                                                placeholder="Ingrese el nombre de la capacitación"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('topic_name')"
                                                :key="`${i}-create-topic_name`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <!-- link -->
                                <b-form-row>
                                    <b-col>
                                        <b-form-group
                                            label="Video (link) : "
                                        >
                                            <pulse-loader
                                                :loading="createCapacitationLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-input
                                                type="text"
                                                v-model="link"
                                                :state="showModalInputStatus('link')"
                                                v-show="!createCapacitationLoading"
                                                placeholder="Ingrese el link del video"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('link')"
                                                :key="`${i}-create-link`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <!-- iframe -->
                                <b-form-row>
                                    <b-col>
                                        <b-form-group
                                            label="Iframe : "
                                        >
                                            <pulse-loader
                                                :loading="createCapacitationLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-input
                                                type="text"
                                                v-model="iframe"
                                                :state="showModalInputStatus('iframe')"
                                                v-show="!createCapacitationLoading"
                                                placeholder="Ingrese el iframe del video"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('iframe')"
                                                :key="`${i}-create-iframe`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <!-- description -->
                                <b-form-row>
                                    <b-col cols="12">
                                        <b-form-group
                                            label="Descripción:" 
                                        >
                                            <pulse-loader
                                                :loading="createCapacitationLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-textarea 
                                                placeholder="Ingrese una descripción (opcional)"
                                                v-model="description"
                                                :state="showModalInputStatus('description')"
                                                v-show="!createCapacitationLoading"
                                                rows="3"
                                                max-rows="6"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('description')"
                                                :key="`${i}-create-resource-description`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <b-row align-v="center" align-h="center">
                                    <b-col cols="auto">
                                        <b-button variant="primary" type="submit"
                                            :disabled="createCapacitationLoading"
                                        >
                                            REGISTRAR
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </b-col>
                    </b-row>
                </b-container>
            </template>
        </b-modal>
        
        <!--
            modal capacitation topic edit
        -->
        <b-modal
            id="modal-capacitation-edit-topic"
            centered
            hide-footer
            size="md"
            no-close-on-backdrop
            no-close-on-esc
        >
            <!-- modal header -->
            <template #modal-header="">
                <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                    <b-row align-h="between" align-v="center">
                        <b-col cols="auto"><h3 class="text-primary mb-0 text-button">EDITAR TÓPICO DE CAPACITACIÓN</h3></b-col>
                        <b-col cols="auto">
                            <b-button size="sm" variant="magenta" @click="modalCapacitationEditClose">
                                <b-icon icon="x" scale="1.5"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <!-- modal content -->
            <template #default>
                <b-container>
                    <b-row>
                        <!-- capacitation form -->
                        <b-col cols="12">
                            <b-form @submit.prevent="editCapacitationTopic">
                                <!-- topic_name -->
                                <b-form-row>
                                    <b-col>
                                        <b-form-group
                                            label="Nombre : "
                                        >
                                            <pulse-loader
                                                :loading="createCapacitationLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-input
                                                type="text"
                                                v-model="capacitationSelected.topic_name"
                                                :state="showModalInputStatus('topic_name')"
                                                v-show="!createCapacitationLoading"
                                                placeholder="Ingrese el nombre de la capacitación"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('topic_name')"
                                                :key="`${i}-create-topic_name`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <!-- link -->
                                <b-form-row>
                                    <b-col>
                                        <b-form-group
                                            label="Video (link) : "
                                        >
                                            <pulse-loader
                                                :loading="createCapacitationLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-input
                                                type="text"
                                                v-model="capacitationSelected.link"
                                                :state="showModalInputStatus('link')"
                                                v-show="!createCapacitationLoading"
                                                placeholder="Ingrese el link del video"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('link')"
                                                :key="`${i}-create-link`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <!-- iframe -->
                                <b-form-row>
                                    <b-col>
                                        <b-form-group
                                            label="Iframe : "
                                        >
                                            <pulse-loader
                                                :loading="createCapacitationLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-input
                                                type="text"
                                                v-model="capacitationSelected.iframe"
                                                :state="showModalInputStatus('iframe')"
                                                v-show="!createCapacitationLoading"
                                                placeholder="Ingrese el iframe del video"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('iframe')"
                                                :key="`${i}-create-iframe`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <!-- description -->
                                <b-form-row>
                                    <b-col cols="12">
                                        <b-form-group
                                            label="Descripción:" 
                                        >
                                            <pulse-loader
                                                :loading="createCapacitationLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-textarea 
                                                placeholder="Ingrese una descripción (opcional)"
                                                v-model="capacitationSelected.description"
                                                :state="showModalInputStatus('description')"
                                                v-show="!createCapacitationLoading"
                                                rows="3"
                                                max-rows="6"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('description')"
                                                :key="`${i}-create-resource-description`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <b-row align-v="center" align-h="center">
                                    <b-col cols="auto">
                                        <b-button variant="primary" type="submit"
                                            :disabled="createCapacitationLoading"
                                        >
                                            REGISTRAR
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </b-col>
                    </b-row>
                </b-container>
            </template>
        </b-modal>
    </b-row>
</template>

<script>
/** programsSeason.capacitations */
import { getProgramSeasonCapacitations } from '@/api/ProgramSeason/ProgramSeasonCapacitations';
import { storeCapacitation, updateCapacitation, destroyCapacitation } from '@/api/Capacitation/Capacitation'
import swal from 'sweetalert'

export default {
    name: "ProgramCapacitationsTopics",
    data: () => ({
        // table 
        tableData: [],
        loadingTableData: false,
        // modal create capacitation topic
        topic_name: '',
        link: '',
        iframe: '',
        description :'',
        // modal edit capacitation topic
        capacitationSelected: {},

        initialModalEditInputValues : true,
        modalEditInputErrors: {},
        createCapacitationLoading: false,
    }),
    created() {
        this.gerCapacitations()
    },
    methods: {
        gerCapacitations () {
            this.loadingTableData = true

            let IdProgramSeason = this.$route.params.IdProgramSeason  // ok

            getProgramSeasonCapacitations(IdProgramSeason)
                .then(({ data }) => {
                    this.tableData = data.data
                })
                .catch( err => {
                    console.log('getProgramSeasonCapacitations Error:', err.response);
                })
                .finally(() => {
                    this.loadingTableData = false
                    console.log('getProgramSeasonCapacitations request end');
                })
        },

        // Modal CREATE
        modalCapacitationCreateClick () {
            this.clearInputsAndStates()
            this.$bvModal.show('modal-capacitation-create-topic')
        },
        modalCapacitationCreateClose () {
            this.$bvModal.hide('modal-capacitation-create-topic')
            this.clearInputsAndStates()
        },

        createCapacitationTopic () {
            this.modalEditInputErrors = {}
            this.initialModalEditInputValues = false
            this.createCapacitationLoading = true

            let IdProgramSeason = this.$route.params.IdProgramSeason  // ok

            let CapacitationForm = new FormData()
            CapacitationForm.append('programSeason_id', IdProgramSeason)
            CapacitationForm.append('topic_name', this.topic_name)
            CapacitationForm.append('link', this.link)
            CapacitationForm.append('ifrane', this.ifrane == null ? '' : this.ifrane )
            CapacitationForm.append('description', this.description == null ? '' : this.description )

            storeCapacitation(CapacitationForm)
                .then(({ data }) => {
                    console.log('createCapacitationTopic res: ', data);
                    swal('Creación correcta!', 'Capacitation creada.', 'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    this.clearInputsAndStates()
                                    this.gerCapacitations()
                                    this.$bvModal.hide('modal-capacitation-create-topic')
                                    break;

                                default:
                                    console.log('createCapacitationTopic--swal-break');
                                    break;
                            }
                        })
                })
                .catch(err => {
                    console.log('createCapacitationTopic err: ', err.response);
                    if (err.response.status === 422) {
                        this.modalEditInputErrors = err.response.data.errors
                    }
                })
                .finally(() => {
                    this.createCapacitationLoading = false
                    console.log('request createCapacitationTopic end');
                })
        },

        modalCapacitationEditClick (pCapacitation) {
            this.clearInputsAndStates()
            this.capacitationSelected = { ...pCapacitation }
            this.$bvModal.show('modal-capacitation-edit-topic')
        },
        modalCapacitationEditClose () {
            this.$bvModal.hide('modal-capacitation-edit-topic')
            this.clearInputsAndStates()
        },

        // Modal EDIT
        editCapacitationTopic () {
            this.modalEditInputErrors = {}
            this.initialModalEditInputValues = false
            this.createCapacitationLoading = true

            let CapacitationForm = new FormData()
            CapacitationForm.append('.method', 'put')
            CapacitationForm.append('topic_name', this.capacitationSelected.topic_name)
            CapacitationForm.append('link', this.capacitationSelected.link)
            CapacitationForm.append('ifrane', this.capacitationSelected.ifrane == null ? '' : this.capacitationSelected.ifrane )
            CapacitationForm.append('description', this.capacitationSelected.description == null ? '' : this.capacitationSelected.description )

            updateCapacitation(CapacitationForm, this.capacitationSelected.id)
                .then(({ data }) => {
                    console.log('updateCapacitationTopic res: ', data);
                    swal('Actualización correcta!', 'Capacitación actualizada.', 'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    this.clearInputsAndStates()
                                    this.gerCapacitations()
                                    this.$bvModal.hide('modal-capacitation-edit-topic')
                                    break;
                                default:
                                    console.log('updateCapacitationTopic--swal-break');
                                    break;
                            }
                        })
                })
                .catch(err => {
                    console.log('updateCapacitationTopic err: ', err.response);
                    if (err.response.status === 422) {
                        this.modalEditInputErrors = err.response.data.errors
                    }
                })
                .finally(() => {
                    this.createCapacitationLoading = false
                    console.log('request updateCapacitationTopic end');
                })
        },

        showModalInputErrors (pInput) {
            if (Object.keys(this.modalEditInputErrors).includes(pInput)) return this.modalEditInputErrors[pInput]
            else return []
        },

        showModalInputStatus (pInput) {
            if (this.initialModalEditInputValues) return null 
            else if (Object.keys(this.modalEditInputErrors).includes(pInput)) return false
            else return true
        },

        clearInputsAndStates () {
            this.initialModalEditInputValues = true
            this.modalEditInputErrors = {}
            //
            this.topic_name = ''
            this.link = ''
            this.ifrane = ''
            this.description = ''
            //
            this.capacitationSelected = {}
        },

        // DELETE
        deleteCapacitation (pCapacitation) {
            swal('Se eliminará la capacitación.', '¿Desea eliminar?', 'error', { dangerMode: true, buttons: ['cancelar', 'Si, eliminar']})
                    .then(value => {
                        switch (value) {
                            case true:
                                destroyCapacitation(pCapacitation.id)
                                    .then(() => {
                                         swal('Se eliminó la capacitación!', 'Eliminado', 'success')
                                            .then(value => {
                                                switch (value) {
                                                    case true:
                                                    case null:
                                                        this.clearInputsAndStates()
                                                        this.gerCapacitations()
                                                        break;
                                                    default:
                                                        console.log('-swal-break');
                                                        break;
                                                }
                                            })
                                    })
                                break;
                            case null:
                                break;
                            default:
                                console.log('-swal-break');
                                break;
                        }
                    })
        },

        reloadData () {
            this.gerCapacitations()
        }
        
    }
}
</script>