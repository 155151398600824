<template>
    <english-interview-list-component :ui="ui" :routeToDetails="detailsRoute"></english-interview-list-component>
</template>

<script>
import EnglishInterviewListComponent from '@/components/Admin-Employee/Postulant-Participant/EnglishInterviewList/EnglishInterviewListComponent.vue'
export default {
    name :'PostulantInterviewList',
    components: { EnglishInterviewListComponent },
    data: () => ({
        ui: 'sales_advisor',
        detailsRoute: 'employee-postulants-interview-details-SA'
    })
}
</script>