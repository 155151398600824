<template>
    <b-row>
        <b-col cols="12" md="8" lg="6">
            <b-card class="shadow">
                 <b-card-header class="">
                    <b-row>
                        <b-col class="text-center">
                            <h3 class="mb-0" >
                                AGENDAR ENTREVISTA
                            </h3>
                        </b-col>
                    </b-row>
                </b-card-header>

                <b-form @submit.prevent="createInterviewEvent" class="mt-8">
                    <b-form-row>
                        <!-- start_date -->
                        <b-col cols="12">
                            <b-form-group
                                label="Fecha :"
                            >
                                <pulse-loader
                                    :loading="createInterviewLoading"
                                    :color="'#5e72e4'"
                                    :margin="'5px'"
                                    :size="10"
                                    class="pt-2 pb-1 pl-2"
                                    style="border-radius:5px; border:1px solid #5e72e4"
                                />

                                <b-form-datepicker
                                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                        selected-variant="primary"
                                    today-variant="naranja"
                                    locale="es"

                                    today-button
                                    label-today-button="today"
                                    today-button-variant="outline-naranja"
                                    
                                    reset-button
                                    label-reset-button="reset"
                                    reset-button-variant="outline-magenta"

                                    close-button
                                    label-close-button="ok"
                                    close-button-variant="primary"

                                    v-model="start_date"
                                    :state="showInputStatus('start_date')"
                                    v-if="!createInterviewLoading"
                                />

                                <b-form-invalid-feedback
                                    v-for="(inputError, i) in showInputErrors('start_date')"
                                    :key="`${i}-interview-start_date`"
                                >
                                    {{ inputError }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </b-col>

                        <!-- start_time -->
                        <b-col cols="12" lg="6">
                            <b-form-group
                                label="Hora de inicio :"
                            >
                                <pulse-loader
                                    :loading="createInterviewLoading"
                                    :color="'#5e72e4'"
                                    :margin="'5px'"
                                    :size="10"
                                    class="pt-2 pb-1 pl-2"
                                    style="border-radius:5px; border:1px solid #5e72e4"
                                />

                                <b-form-timepicker
                                    locale="en"

                                    now-button
                                    label-now-button="now"
                                    now-button-variant="outline-naranja"

                                    reset-button
                                    label-reset-button="reset"
                                    reset-button-variant="outline-magenta"

                                    close-button
                                    label-close-button="ok"
                                    close-button-variant="primary"

                                    v-model="start_time"
                                    :state="showInputStatus('start_time')"
                                    v-if="!createInterviewLoading"
                                />

                                <b-form-invalid-feedback
                                    v-for="(inputError, i) in showInputErrors('start_time')"
                                    :key="`${i}-interview-start_time`"
                                >
                                    {{ inputError }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </b-col>

                        <!-- end_time -->
                        <b-col cols="12" lg="6">
                            <b-form-group
                                label="Hora de finalización :"
                            >
                                <pulse-loader
                                    :loading="createInterviewLoading"
                                    :color="'#5e72e4'"
                                    :margin="'5px'"
                                    :size="10"
                                    class="pt-2 pb-1 pl-2"
                                    style="border-radius:5px; border:1px solid #5e72e4"
                                />

                                <b-form-timepicker
                                    locale="en"

                                    now-button
                                    label-now-button="now"
                                    now-button-variant="outline-naranja"

                                    reset-button
                                    label-reset-button="reset"
                                    reset-button-variant="outline-magenta"

                                    close-button
                                    label-close-button="ok"
                                    close-button-variant="primary"

                                    v-model="end_time"
                                    :state="showInputStatus('end_time')"
                                    v-if="!createInterviewLoading"
                                />

                                <b-form-invalid-feedback
                                    v-for="(inputError, i) in showInputErrors('end_time')"
                                    :key="`${i}-interview-end_time`"
                                >
                                    {{ inputError }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </b-col>

                        <!-- link -->
                        <b-col cols="12">
                            <b-form-group label="Link :" >
                                <pulse-loader
                                    :loading="createInterviewLoading"
                                    :color="'#5e72e4'"
                                    :margin="'5px'"
                                    :size="10"
                                    class="pt-2 pb-1 pl-2"
                                    style="border-radius:5px; border:1px solid #5e72e4"
                                />

                                <b-form-input
                                    type="url"
                                    placeholder="Ingrese el link de la reunión."
                                    v-model="link"
                                    :state="showInputStatus('link')"
                                    v-show="!createInterviewLoading"
                                />

                                <b-form-invalid-feedback
                                    v-for="(inputError, i) in showInputErrors('link')"
                                    :key="`${i}-createVoucher-link`"
                                >
                                    {{ inputError }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </b-col>

                        <!-- description -->
                        <b-col cols="12">
                            <b-form-group
                                label="Descripción:" 
                            >
                                <pulse-loader
                                    :loading="createInterviewLoading"
                                    :color="'#5e72e4'"
                                    :margin="'5px'"
                                    :size="10"
                                    class="pt-2 pb-1 pl-2"
                                    style="border-radius:5px; border:1px solid #5e72e4"
                                />

                                <b-form-textarea 
                                    placeholder="Ingrese una descripción (opcional)"
                                    v-model="schedule_description"
                                    :state="showInputStatus('schedule_description')"
                                    v-show="!createInterviewLoading"
                                    rows="3"
                                    max-rows="6"
                                />

                                <b-form-invalid-feedback
                                    v-for="(inputError, i) in showInputErrors('schedule_description')"
                                    :key="`${i}-interview-schedule_description`"
                                >
                                    {{ inputError }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </b-col>
                    </b-form-row>

                    <b-row align-v="center" align-h="center">
                        <b-col cols="auto">
                            <b-button 
                                variant="primary"
                                type="submit"
                                size="lg"
                                :disabled="createInterviewLoading"
                            >
                                AGENDAR
                            </b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import { storeEnglishInterviewSchedule } from '@/api/EnglishInterviewSchedule/EnglishInterviewSchedule'
import swal from 'sweetalert'

export default {
    name: 'CreateInterview',
    data: () => ({
        subject: 'Entrevista de Inglés',            // default
        start_date: '',
        start_time: '',
        end_date: '',
        end_time: '',
        link: '',
        color: '#5E8B7E',
        schedule_description: '',
        schedule_status: 'disponible',

        //
        createInterviewLoading: false,
        initialInputValues: true,
        inputErrors: {}
    }),

    computed: {
        salesAdvisorPermissionData () {
            return this.$store.getters['user/getEmployeePermissionData']('asesor-ventas')
        },
    },

    methods: {
        createInterviewEvent() {
            this.createInterviewLoading = true
            this.inputErrors = {}
            this.initialInputValues = false
            //
            let IdSalesAdvisor = this.salesAdvisorPermissionData.attributes.typeAdvisor_id   // ok

            const InterviewFormData = new FormData()
            InterviewFormData.append('employeeCalendar_id', this.$store.state.user.attributes.employeeCalendar_id)
            InterviewFormData.append('salesAdvisor_id', IdSalesAdvisor)
            // InterviewFormData.append('culturalAdvisor_id')    // cuando la agenda es de CA

            InterviewFormData.append('start_date', this.start_date)
            InterviewFormData.append('start_time', this.start_time)
            InterviewFormData.append('end_date', this.start_date)                   // this.start_date; la entrevista es el mismo dia
            InterviewFormData.append('end_time', this.end_time)
            
            InterviewFormData.append('link', this.link)
            InterviewFormData.append('color', this.color)                               // '#5E8B7'
            InterviewFormData.append('schedule_description', this.schedule_description) // 'Entrevista de Inglés'
            InterviewFormData.append('schedule_status', this.schedule_status)           // 'disponible

            storeEnglishInterviewSchedule(InterviewFormData)
                .then(() => {
                    swal('Creación correcta!', 'Horario de entrevista disponible creado.', 'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    this.$router.push({ name: 'employee-schedule-dashboard'})
                                    break;
                                default:
                                    console.log('create english interview res swal-break');
                                    break;
                            }
                        })
                })
                .catch(err => {
                    if (err.response.status == 422) {
                        this.inputErrors = err.response.data.errors
                    }
                })
                .finally(() => {
                    this.createInterviewLoading = false
                })
        },

        showInputErrors (pInput) {
            if (Object.keys(this.inputErrors).includes(pInput)) return this.inputErrors[pInput]
            else return []
        },

        showInputStatus (pInput) {
            if (this.initialInputValues) return null 
            else if (Object.keys(this.inputErrors).includes(pInput)) return false
            else return true
        },
    } 
}
</script>