<template>
    <documents-filter-component
        :ui="ui"
        :routeDetails="routeDetails"
        :routeDocuments="routeDocuments"
    ></documents-filter-component>
</template>

<script>
import DocumentsFilterComponent from '@/components/Admin-Employee/Participant/Filters/DocumentsFilterComponent.vue'

export default {
    name : 'DocumentsFilter',
    components: { DocumentsFilterComponent },
    data: () => ({
        ui: 'employee',
        routeDetails: 'employee-participant-details',
        routeDocuments: 'employee-participant-documents'
    })
}
</script>