<template>
    <program-capacitations-topics></program-capacitations-topics>
</template>

<script>
import ProgramCapacitationsTopics from '@/views/Employee/ProgramComponents/ProgramDetails/ProgramCapacitations/ProgramCapacitationsTopics.vue'

export default {
    name: "InternshipCapacitationsTopics",
    components: { ProgramCapacitationsTopics },
}
</script>