<template>
    <b-row align-h="center">
        <b-col cols="auto" class="mb-4">
            <b-card no-body border-variant="transparent" bg-variant="transparent" class="glass">
                <div style="height:50px;width:250px" v-show="programSeasonSponsorLoading">
                    <pulse-loader
                        :color="'#fff'"
                        :margin="'10px'"
                        :size="15"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,-40%)"/>
                </div>

                <b-card-body
                    v-show="!programSeasonSponsorLoading"
                    class="py-2 text-uppercase text-center text-white"
                    style="font-weight: bolder"
                    body-border-variant="transparent"
                    body-bg-variant="transparent"
                >
                    {{ programSeasonSponsor.attributes.sponsor_name }}
                </b-card-body>
            </b-card>
        </b-col>

        <b-col cols="12" class="mb-4">
            <div class="intranet-nav-content">
                <intranet-nav-content-link-exact :link="{ name: 'Programación', path: { name: 'employee-wat-sponsor-programation' } }"/>
                <intranet-nav-content-link-exact :link="{ name: 'Detalles', path: { name: 'employee-wat-sponsor-details' } }"/>
                <intranet-nav-content-link :link="{ name: 'Ofertas Laborales', path: { name: 'employee-wat-sponsor-joboffers' } }"/>
                <intranet-nav-content-link :link="{ name: 'Postulaciones', path: { name: 'employee-wat-sponsor-postulations' } }"/>
                <!-- <intranet-nav-content-link :link="{ name: 'Entrevistas', path: { name: 'employee-wat-sponsor-interviews' } }"/> -->
            </div>
        </b-col>

        <b-col>
            <slide-y-down-transition :duration="350" origin="center top" mode="out-in">
                <router-view></router-view>
            </slide-y-down-transition>
        </b-col>
    </b-row>

</template>

<script>
import { getProgramSeasonSponsor } from '@/api/ProgramSeasonSponsor/ProgramSeasonSponsor'
import IntranetNavContentLink from '@/layout/NavContent/IntranetNavContentLink';
import IntranetNavContentLinkExact from '@/layout/NavContent/IntranetNavContentLinkExact';
import { SlideYDownTransition  } from 'vue2-transitions';
//import { PulseLoader } from '@saeris/vue-spinners'


export default {
    name: 'SponsorDetails',
    components: { 
        IntranetNavContentLink,
        IntranetNavContentLinkExact,
        SlideYDownTransition,
        // PulseLoader
    },
    data: () => ({
        programSeasonSponsor: {
            attributes: {}
        },
        programSeasonSponsorLoading: false
    }),
    created () {
        this.getData()
    },
    methods: {
        getData () {
            this.programSeasonSponsorLoading = true

            let IdProgramSeasonSponsor = this.$route.params.IdProgramSeasonSponsor //ok

            getProgramSeasonSponsor(IdProgramSeasonSponsor)
                .then(({data}) => {
                    this.programSeasonSponsor = data.data
                })
                .finally(() => {
                    this.programSeasonSponsorLoading = false
                })
        }
    }
};
</script>

<style scoped>
.glass {
    background-color: rgba(255, 255, 255, .3) !important;
    /* background-color: rgba(94, 114, 228, 0.3) !important; */
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1) !important;
    backdrop-filter: blur(5px) !important;
    color: white !important;
    border:1px solid rgba(255, 255, 255, 0.9) !important;
    /* border:1px solid rgba(94, 114, 228, 0.9) !important; */
    overflow: hidden;
}
.intranet-nav-content {
    display: flex;
    align-items: center;
    overflow-x:auto;
    white-space:nowrap;
}
</style>