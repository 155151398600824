<template>
    <program-sponsor-postulations />
</template>

<script>
import ProgramSponsorPostulations from '@/views/Employee/ProgramComponents/ProgramDetails/ProgramSponsors/ProgramSponsorDetails/ProgramSponsorPostulations.vue'

export default {
    name: "TraineeSponsorPostulations",
    components: { ProgramSponsorPostulations }
}
</script>