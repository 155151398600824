import { post } from 'axios'
import store from './../../store/index.js'

const BASE_URL = store.state.api.baseUrl
const headers = { Authorization: `Bearer ${store.state.user.attributes.token}` }

//post
function culturalAdvisorSeasonProgramSeasonPostulantSearch (pFormdata, pIdCulturalAdvisor, pIdSeason, pPage) {
    const URI = `${BASE_URL}/api/culturalAdvisors/${pIdCulturalAdvisor}/seasons/${pIdSeason}/programSeasonPostulant-search?page=${pPage}`
    return post(URI, pFormdata, { headers })
}

export { culturalAdvisorSeasonProgramSeasonPostulantSearch }