<template>
    <b-row>
        <b-col>
            <b-card no-body>
                <b-card-header class="border-0">
                    <b-row class="align-items-center">
                        <b-col>
                            <h3 class="mb-0 text-primary text-button">
                                Temporadas
                            </h3>
                        </b-col>

                        <b-col class="text-right">
                            <b-button variant="primary" size="sm" @click="reloadData">
                                <b-icon icon="arrow-repeat" scale="1.3"></b-icon>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card-header>

                <!-- laodingTableData -->
                <div style="height:300px;" v-show="loadingTableData">
                    <pulse-loader
                        :color="'#5e72e4'"
                        :margin="'10px'"
                        :size="20"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,50%)"/>
                </div>

                <!-- table -->
                <div class="table-responsive" v-show="!loadingTableData">
                    <base-table class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="tableData"
                    >
                        <template slot="columns">
                            <th>#</th>
                            <th>programa</th>
                            <th>temporada</th>
                            <th>descripción</th>
                            <th class="text-center">detalles</th>
                        </template>

                        <template slot-scope="{row, index}">
                            <td>{{ index + 1 }}</td>
                            <td>{{ row.attributes.program_name }}</td>
                            <td>{{ row.attributes.season_name }}</td>
                            <td>{{ row.attributes.description }}</td>

                            <td class="text-center">
                                <b-btn 
                                    :to="{
                                        name: 'employee-teach-details',
                                        params: { 'IdProgramSeason': row.id }    //IdProgramSeason
                                    }"
                                    variant="outline-magenta"
                                    size="sm"
                                ><b-icon icon="list-task" variant="magenta"></b-icon>
                                </b-btn>
                            </td>
                        </template>
                    </base-table>
                </div>

                <!-- pagination -->
                <div class="card-footer" v-show="!loadingTableData">
                    <base-pagination
                        align="center"
                        :pageCount="tableMeta.last_page" 
                        :perPage="tableMeta.per_page"
                        :value="tableMeta.current_page"
                        @input="paginationCLiked"
                    />
                </div>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import { getProgramProgramSeasons } from '@/api/Program/ProgramProgramSeason';

export default {
    name: "TeachList",
    data: () => ({
        tableData: [],
        tableMeta: {},
        loadingTableData: false
    }),
    created() {
        this.getData()
    },
    methods: {
        getData (pPage = 1) {
            this.loadingTableData = true
            let IdProgram = 5    // 5 teach - ok

            getProgramProgramSeasons(IdProgram, pPage)
                .then(({ data }) => {
                    this.tableData = data.data
                    this.tableMeta = data.meta
                })
                .finally(() => {
                    this.loadingTableData = false
                })
        },
        paginationCLiked (e) {
            this.getData(e)
        },
        reloadData() {
            this.getData()
        }
    }
}
</script>