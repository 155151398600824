<template>
    <b-row>
        <b-col cols="12" class="mb-4">
            <b-card no-body border-variant="transparent" bg-variant="transparent" class="glass">
                <div style="height:50px;" v-show="programSeasonLoading">
                    <pulse-loader
                        :color="'#fff'"
                        :margin="'10px'"
                        :size="20"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,-40%)"/>
                </div>

                <b-card-body
                    v-show="!programSeasonLoading"
                    class="py-2 text-uppercase text-center text-white"
                    style="font-weight: bolder"
                    body-border-variant="transparent"
                    body-bg-variant="transparent"
                >
                    {{ programSeason.attributes.program_name }} - {{ programSeason.attributes.season_name }}
                </b-card-body>
            </b-card>
        </b-col>

        <b-col cols="12" class="mb-4">
            <div class="intranet-nav-content">
                <intranet-nav-content-link-exact :link="{ name: 'Detalles', path: { name: 'employee-aupair-details' } }"/>
                <intranet-nav-content-link :link="{ name: 'Documentos', path: { name: 'employee-aupair-documents' } }"/>
                <!-- <intranet-nav-content-link :link="{ name: 'Postulantes', path: { name: 'employee-aupair-postulants' } }"/> -->
                <!-- <intranet-nav-content-link :link="{ name: 'Participantes', path: { name: 'employee-aupair-participants' } }"/> -->
                <intranet-nav-content-link :link="{ name: 'Recursos', path: { name: 'employee-aupair-resources' } }"/>
                <intranet-nav-content-link :link="{ name: 'Capacitaciones', path: { name: 'employee-aupair-capacitations-topics' } }"/>
                <intranet-nav-content-link :link="{ name: 'Charlas', path: { name: 'employee-aupair-talks-topics' } }"/>
               <!--  -->
                <intranet-nav-content-link :link="{ name: 'Sponsors', path: { name: 'employee-aupair-sponsors' } }"/>
            </div>
        </b-col>

        <b-col>
            <slide-x-left-transition :duration="350" origin="center top" mode="out-in">
                <router-view></router-view>
            </slide-x-left-transition>
        </b-col>
    </b-row>

</template>

<script>
import { getProgramSeason } from '@/api/ProgramSeason/ProgramSeason'
import IntranetNavContentLink from '@/layout/NavContent/IntranetNavContentLink';
import IntranetNavContentLinkExact from '@/layout/NavContent/IntranetNavContentLinkExact';
import { SlideXLeftTransition   } from 'vue2-transitions';
//import { PulseLoader } from '@saeris/vue-spinners'

export default {
    name: 'Aupair',
    components: { 
        IntranetNavContentLink,
        IntranetNavContentLinkExact,
        SlideXLeftTransition,
        // PulseLoader
    },
           data: () => ({
        programSeason: {
            attributes: {}
        },
        programSeasonLoading: false
    }),
    created () {
        this.getData()
    },
    methods: {
        getData () {
            this.programSeasonLoading = true

            let IdProgramSeason = this.$route.params.IdProgramSeason // ok

            getProgramSeason(IdProgramSeason)
                .then(({data}) => {
                    this.programSeason = data.data
                })
                .catch(err => {
                    console.log('getProgramSeason err', err);
                })
                .finally(() => {
                    this.programSeasonLoading = false
                    console.log('request getProgramSeason end');
                })
        }
    }
};
</script>

<style scoped>
.glass {
    background-color: rgba(255, 255, 255, .3) !important;
    /* background-color: rgba(255, 173, 0, .45) !important; */
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1) !important;
    backdrop-filter: blur(5px) !important;
    color: white !important;
    border:1px solid rgba(255, 255, 255, 0.9) !important;
    /* border:1px solid rgba(255, 173, 0, 0.9) !important; */
    overflow: hidden;
}
.intranet-nav-content {
    display: flex;
    align-items: center;
    overflow-x:auto;
    white-space:nowrap;
}
</style>