import axios from 'axios'
import store from './../../store/index.js'

const BASE_URL = store.state.api.baseUrl
const headers = { Authorization : `Bearer ${store.state.user.attributes.token}` }

function storeCapacitation (pFormData) {
    const URI = `${BASE_URL}/api/capacitations`
    return axios.post(URI, pFormData, { headers })
}

function updateCapacitation (pFormData, pIdCapacitation) {
    const URI = `${BASE_URL}/api/capacitations/${pIdCapacitation}`
    return axios.post(URI, pFormData, { headers })
}

function destroyCapacitation (pIdCapacitation) {
    const URI = `${BASE_URL}/api/capacitations/${pIdCapacitation}`
    return axios.delete(URI, { headers })
}

export {
    storeCapacitation,
    updateCapacitation,
    destroyCapacitation
}