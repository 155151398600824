import { get } from 'axios'
import store from './../../store/index.js'

const BASE_URL = store.state.api.baseUrl
const headers = { Authorization: `Bearer ${store.state.user.attributes.token}` }

function getProgramSeasonCapacitations (pIdProgramSeason) {
    const URI = `${BASE_URL}/api/programSeasons/${pIdProgramSeason}/capacitations`
    return get(URI, { headers })
}
export { getProgramSeasonCapacitations }