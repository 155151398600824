<template>
    <english-interview-list-component :ui="ui" :routeToDetails="detailsRoute"></english-interview-list-component>
</template>

<script>
import EnglishInterviewListComponent from '@/components/Admin-Employee/Postulant-Participant/EnglishInterviewList/EnglishInterviewListComponent.vue'
export default {
    name :'InterviewList',
    components: { EnglishInterviewListComponent },
    data: () => ({
        ui: 'cultural_advisor',
        detailsRoute: 'employee-participant-interview-details'
    })
}
</script>