<template>
    <!-- Calendar by Vuetify - SalesAdvisorCalendar -->
    <v-row class="fill-height">
        <v-col>
            <v-sheet height="64" class="mx-0 px-0">
                <v-toolbar flat>
                    <!-- btn today -->
                    <v-btn
                        outlined
                        class="mr-2"
                        color="grey darken-2"
                        @click="setToday"
                    >hoy
                    </v-btn>

                    <!-- reload -->
                    <b-button variant="primary" size="sm" @click="reloadData">
                        <b-icon icon="arrow-repeat"></b-icon>
                    </b-button>

                    <!-- btn prev month -->
                    <v-btn
                        fab
                        text
                        small
                        color="grey darken-2"
                        @click="prev"
                    >
                        <v-icon small>
                        mdi-chevron-left
                        </v-icon>
                    </v-btn>

                      <div>
                        SA
                    </div>

                    <!-- btn next month -->
                    <v-btn
                        fab
                        text
                        small
                        color="grey darken-2"
                        @click="next"
                    >
                        <v-icon small>mdi-chevron-right</v-icon>
                    </v-btn>

                    <!-- month - year -->
                    <v-toolbar-title v-if="$refs.calendar">
                        {{ $refs.calendar.title }}
                    </v-toolbar-title>

                    <v-spacer></v-spacer>

                    <v-menu bottom right >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                outlined
                                color="grey darken-2"
                                v-bind="attrs"
                                v-on="on"
                            >
                                <span>{{ typeToLabel[type] }}</span>
                                <v-icon right>mdi-menu-down</v-icon>
                            </v-btn>
                        </template>

                        <v-list>
                            <v-list-item @click="type = 'day'">
                                <v-list-item-title>Día</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="type = 'week'">
                                <v-list-item-title>Semana</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="type = 'month'">
                                <v-list-item-title>Mes</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="type = '4day'">
                                <v-list-item-title>4 días</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-toolbar>
            </v-sheet>

            <v-sheet height="600">
                <v-calendar
                    locale="es"
                    :short-weekdays="false"
                    ref="calendar"
                    v-model="focus"
                    color="primary"
                    :events="events"
                    :event-color="getEventColor"
                    :type="type"
                    @click:event="showEvent"
                    @click:more="viewDay"
                    @click:date="viewDay"
                    @change="updateRange"
                    
                    >
                </v-calendar>

                <!-- evento seleccionado -->
                <v-menu
                    v-model="selectedOpen"
                    :close-on-content-click="false"
                    :activator="selectedElement"
                    offset-x
                >
                    <v-card
                        color="grey lighten-4"
                        flat
                        class="card-event-display"
                    >
                        <v-toolbar :color="selectedEvent.color" dark>
                            <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn icon @click="selectedOpen = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar>

                        <v-card-text>
                            <b-row>
                                <b-col class="text-caption text-uppercase text-center
                                    card-english-interview-schedule-agenda mx-2 py-1"
                                    :class="selectedEvent.schedule_status == 'pendiente' ? 'text-magenta' :( selectedEvent.schedule_status == 'disponible' ? 'text-schedule-english-2' : 'text-schedule-english-1')"
                                >
                                    <span>Agenda </span>
                                    <span>{{ selectedEvent.salesAdvisor_id ? 'Asesor ventas' : 'Asesor cultural' }}</span>
                                </b-col>
                            </b-row>

                            <b-row class="text-caption">
                                <b-col cols="4" class="text-right">Estado</b-col>
                                <b-col cols="1" class="text-center">:</b-col>
                                <b-col cols="6">
                                    <span   :class="selectedEvent.schedule_status == 'pendiente' ? 'text-magenta' :( selectedEvent.schedule_status == 'disponible' ? 'text-schedule-english-2' : 'text-schedule-english-1') ">
                                        {{ selectedEvent.schedule_status }}
                                    </span>
                                </b-col>
                            </b-row>

                            <b-row class="text-caption"
                                v-if="selectedEvent.details"
                            >
                                <b-col cols="4" class="text-right">Detalles</b-col>
                                <b-col cols="1" class="text-center">:</b-col>
                                <b-col cols="6">
                                    <span>{{ selectedEvent.details }} </span>
                                </b-col>
                            </b-row>

                            <b-row class="text-caption">
                                <b-col cols="4" class="text-right">Hora</b-col>
                                <b-col cols="1" class="text-center">:</b-col>
                                <b-col cols="6">{{ selectedEvent.start_time }} a {{ selectedEvent.end_time }}</b-col>
                            </b-row>
                        </v-card-text>

                        <v-card-actions>
                            <b-row style="flex-direction: column">
                                <b-col v-if="requestError != ''"  style="max-width:420px">
                                    <b-alert show variant="magenta" >
                                        {{ requestError }}
                                    </b-alert>
                                </b-col>

                                <b-col class="text-center">
                                    <v-btn
                                        :loading="scheduleLoading"
                                        text
                                        color="schedule-english-3"
                                        @click.prevent="schedule"
                                    >
                                        Reservar
                                    </v-btn>
                                </b-col>
                            </b-row>
                        </v-card-actions>
                    </v-card>
                </v-menu>
            </v-sheet>
        </v-col>
    </v-row>
</template>

<script>
import { getSalesAdvisorEnglishInterviewSchedulesAvailables } from '@/api/SalesAdvisor/EnglishInterviewScheduleAvailable'
import { scheduleEnglishInterview } from '@/api/EnglishInterviewSchedule/ScheduleEnglishInterview'
import swal from 'sweetalert'

export default {
    name: 'SalesAdvisorCalendar',

    //
    props: {
        salesAdvisor_id: {
            type: Number
        }
    },

    data: () => ({
        color: '',
        focus: new Date().toISOString().substring(0,10),
        type: 'month',
        typeToLabel: {
            month: 'Mes',
            week: 'Semana',
            day: 'Día',
            '4day': '4 días',
        },
        selectedEvent: {},
        selectedElement: null,
        selectedOpen: false,
        events: [],
        /**
         * 
         */
        scheduleLoading: false,

        requestError: ''
    }),

    mounted () {
        this.$refs.calendar.checkChange()
    },

    methods: {
        //
        reloadData() {
            this.requestError = ''
            this.geSalesAdvisorCalendarEvents()
        },
        /**
         * OWN CALENDAR METHODS
         */
        viewDay ({ date }) {
            this.focus = date
            this.type = 'day'
        },
        getEventColor (event) {
            return event.color
        },
        setToday () {
            this.focus = new Date().toISOString().substring(0,10)
        },
        prev () {
            this.$refs.calendar.prev()
        },
        next () {
            this.$refs.calendar.next()
        },
        showEvent ({ nativeEvent, event }) {
            const open = () => {
                this.selectedEvent = event
                this.selectedElement = nativeEvent.target
                requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
            }

            if (this.selectedOpen) {
                this.selectedOpen = false
                requestAnimationFrame(() => requestAnimationFrame(() => open()))
            } else {
                open()
            }

            nativeEvent.stopPropagation()
        },

        /**
         * 
         */
        updateRange ({ start, end }) {
            console.log('start:', start, '-- end: ', end);
            this.requestError = ''
            this.geSalesAdvisorCalendarEvents()
        },

        // geSalesAdvisorCalendarEvents
        geSalesAdvisorCalendarEvents () {
            getSalesAdvisorEnglishInterviewSchedulesAvailables(this.salesAdvisor_id)
                .then(({data}) => {
                    this.events = data.data.map(el => {
                        return {
                            englishInterviewSchedule_id : el.attributes.id,
                            name                        : el.attributes.subject,
                            date                        : el.attributes.start_date,
                            start                       : el.attributes.start,
                            end                         : el.attributes.end,
                            start_time                  : el.attributes.start_time,
                            end_time                    : el.attributes.end_time,
                            color                       : el.attributes.color,
                            details                     : el.attributes.schedule_description,
                            link                        : el.attributes.link,
                            schedule_status             : el.attributes.schedule_status,
                            timed                       : true,
                        }
                    })
                })
        },

        schedule () {
            this.scheduleLoading = true

            //
            let IdEnglishInterviewSchedule = this.selectedEvent.englishInterviewSchedule_id
            let IdProgramSeasonPostulant = this.$route.params.IdProgramSeasonPostulant
            //
            const scheduleFormData = new FormData()
            scheduleFormData.append('.method', 'put')

            scheduleEnglishInterview(scheduleFormData, IdEnglishInterviewSchedule, IdProgramSeasonPostulant)
                .then(() => {
                    swal('Agendación correcta!', 'Su entrevista fue agendada.', 'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    this.$router.push({ name: 'postulant-program-interviews-list'})
                                    break;

                                default:
                                    console.log('scheduleEnglishInterview-swal-break');
                                    break;
                            }
                        })
                })
                .catch(err => {
                    if (err.response) {
                        this.requestError = err.response.status + ' : ' + err.response.data.message
                    }
                })
                .finally(() => {
                    this.scheduleLoading = false
                })
        }
    },
  }
</script>

<style scoped>
.card-english-interview-schedule-agenda {
    border: 1.5px solid;
    border-radius: 5px;
}
@media (max-width: 575px) {
    .card-event-display {
        width: 350px;
    }
}
@media (min-width: 576px) {
    .card-event-display {
        min-width: 350px;
    }
}
</style>