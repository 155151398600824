import { put } from 'axios'
// import store from '@/store'
import store from './../../store/index.js'

const BASE_URL = store.state.api.baseUrl
const headers = { Authorization: `Bearer ${store.state.user.attributes.token}` }

//post
function scheduleEnglishInterview (pFormData, pIdEnglishInterviewShedule, pIdProgramSeasonPostulant) {
    const URI = `${BASE_URL}/api/schedule/englishInterviewSchedule/${pIdEnglishInterviewShedule}/programSeasonPostulant/${pIdProgramSeasonPostulant}`
    return put(URI, pFormData, { headers })
}

export { scheduleEnglishInterview }